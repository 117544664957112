.homewrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__info,
    &__map {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        margin: 0;
    }

    &__menu {
        padding: 0 1.5rem;
    }

    &__title {
        padding: 0 2rem;

        span {
            color: grey;
        }
    }

    .paymentsList {
        span {

            display: flex;
            gap: 0.625rem;
            color: #000AFF;
            cursor: pointer;
        }
    }

    &__content {
        padding: 1.25rem 1.5rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        p {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 400;
            line-height: 16.8px;

            b {
                color: #000AFF
            }
        }
    }

    &__loanBtn {
        display: flex;
        width: 10rem;
        margin: 0 auto;
    }

    @media (max-width: 767px) {
        .btn-send-loan {
            display: flex;
            cursor: pointer;
            position: fixed;
            bottom: 10px;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 0;
            width: 48px;
            height: 48px;
            min-width: auto;
            min-height: 48px;
            border-radius: 50%;
            box-shadow: 2px 4px 6px 0px #00000040,
                0px -2px 4px 0px #00000040 inset,
                2px 6px 4px 0px #FFFFFF40 inset,
                0px 1px 4px 0px #370FD0 inset,
                -2px 0px 4px 0px #00000040 inset;

            span {
                position: absolute;
                text-indent: -99999px;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' stroke='none' fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2143 8.21429H12.1429C11.9456 8.21429 11.7857 8.05437 11.7857 7.85714V1.78571C11.7857 0.799561 10.9862 0 10 0C9.01385 0 8.21429 0.799561 8.21429 1.78571V7.85714C8.21429 8.05437 8.05437 8.21429 7.85714 8.21429H1.78571C0.799561 8.21429 0 9.01385 0 10C0 10.9862 0.799561 11.7857 1.78571 11.7857H7.85714C8.05437 11.7857 8.21429 11.9456 8.21429 12.1429V18.2143C8.21429 19.2004 9.01385 20 10 20C10.9862 20 11.7857 19.2004 11.7857 18.2143V12.1429C11.7857 11.9456 11.9456 11.7857 12.1429 11.7857H18.2143C19.2004 11.7857 20 10.9862 20 10C20 9.01385 19.2004 8.21429 18.2143 8.21429Z' /%3E%3C/svg%3E%0A") 50% 50% no-repeat;
            }
        }
    }
}
@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;