.table-item {
  padding: 0;
  margin: 0 auto;
}

.table-item__border {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  // border: 1px solid black !important;
  // box-sizing: border-box;
}

.ant-table-small .ant-table-thead > tr > th {
    font-family: "Nunito", sans-serif;
    // font-size: 0.9rem;
    font-weight: 700;
    // line-height: 1.6;
    // color: #212529 !important;
    text-align: left;
}

.ant-table-cell {
  align-items: center;
}
@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;