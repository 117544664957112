.login-wrapper {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  opacity: 1;
  transition: all .3s;
  @media screen and (max-width:480px){
    background-color: #bebebe;
    background-image: none!important;
  }
}

.login-wrapper-none {
  opacity: 0;
}

.login-column {
  height: 100vh;
  // background: rgba(255, 255, 255, 0.6);
  background-color: #F3F3F3;
  overflow: auto;
}

.login-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.ant-form-item-hidden, 
.ant-form-item-hidden.ant-row,
.ant-form-hidden {
  display: none;
}
.login-content {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  margin: auto;
  .ant-form-item{
    margin-bottom: 10px;
  }
  &__logo-holder{
    max-width: 200px;
    margin-top: 20%;
    @media screen and (max-width:480px){
      margin-top: 30px;
    }
    img{
      display: block;
      width: 100%;
      max-width: 150px;
    }
  }
  h1 {
    // font-weight: 400 ;
    // font-size: 20px;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 40px;
  }
  
}

.login-form-wrapper {
  width: 100%;
}

// .login-form {
  // max-width: 300px;
// }
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
    p {
      font-size: 1.125rem;
      line-height: 1.5;
    }
}

.login-form-wrapper .ant-alert-error {
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}

.login-form-wrapper .ant-alert-message {
  color: #761b18;
}

.form-text {
  display: block;
  margin-top: .25rem;
  font-size: 80%;
  font-weight: 400;
    p {
      margin-bottom: 0;
    }
}

.register-btn {
  width: auto;
  border: none;
}

.login-forget-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  p{
    color:rgba(51, 51, 51, 0.5);
    margin-bottom: 0;
    font-size: 14px;
  }
  button {
    padding: 0 5px;
  }

  &_policy{
    //margin-top: 20px;
    flex-direction: column;
    align-items: center;
    button + button{
      margin-top: -5px;
    }
  }
}

@media all and (min-width: 568px){

  .login-content{
    h1{
      font-size: 2em;
    }
    padding-bottom: 10%;
    &__logo-holder{
      max-width: 280px;
    }
    .ant-form-item{
      margin-bottom: 24px;
    }
  }

  .login-forget-holder {
    &_policy{
      margin-top: 20px;
    }
  }
}

@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;