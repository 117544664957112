.loader-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  background-color: rgba(255,255,255,0.5);
  opacity: 1;
}

.loader-wrapper-none {
  opacity: 0;
  z-index: -1;
}
@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;