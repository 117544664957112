.__homeLink{
  background-color: rgba(255,255,255,0);
  &.ant-menu-dark.ant-menu-inline{
    background-color: rgba(255,255,255,0);
    li{
        position: relative;
        display: inline-block;
        padding: 10px;
        height: auto;
        line-height: 1;
        min-height: auto;
        margin-bottom: 5px;
        background: url("data:image/svg+xml,%3Csvg width='16' height='21' viewBox='0 0 16 21' stroke='%23000AFF' stroke-opacity='1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 10.5H11M5 14.5H11M13 19.5H3C2.46957 19.5 1.96086 19.2893 1.58579 18.9142C1.21071 18.5391 1 18.0304 1 17.5V3.5C1 2.96957 1.21071 2.46086 1.58579 2.08579C1.96086 1.71071 2.46957 1.5 3 1.5H8.586C8.8512 1.50006 9.10551 1.60545 9.293 1.793L14.707 7.207C14.8946 7.39449 14.9999 7.6488 15 7.914V17.5C15 18.0304 14.7893 18.5391 14.4142 18.9142C14.0391 19.2893 13.5304 19.5 13 19.5Z' /%3E%3C/svg%3E%0A") 0% 50% no-repeat;

        &.ant-menu-item-only-child{
            margin-bottom: 5px!important;
        }
        &.ant-menu-item-selected{
            background-color: rgba(255,255,255,0);
            span{
                color:#333;
            }
        }
        span{
            font-size: 16px;
            font-weight: normal;
            color:#333;
            
        }
        &:hover{
            span{
                color:#000AFF;
            }
        }
    }
  }
}
@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;