.site-layout-background {
  min-height: 360px;
  p {
    margin: 0;
  }
  .ant-table-content {
    font-family: "Nunito", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: left;
    p {
      margin: 0;
    }
    .ant-table-thead .ant-table-cell {
      font-family: "Nunito", sans-serif;
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 1.6;
      color: #212529 !important;
      text-align: left;
    }
  }

  .ant-table-header .ant-table-thead > tr > th {
    font-family: "Nunito", sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.6;
    color: #212529 !important;
    text-align: left;
  }

  .ant-col {
    + .ant-col {
      text-align: right;
    }
  }
}
.homewrap{
  width: 100%;
  display: inline-block;
  & > * {
    margin-left:1rem;
    margin-right: 1rem;
  }
}
.tabs-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 0;
  font-weight: 500;
  line-height: 1.2;
  border-bottom: 1px solid #dee2e6!important;

  button {
    margin-left: 25px;
  }
  .btn-send-loan,
  .btn-send-message{
    width: auto;
    min-width: auto;
    padding: 15px;
    margin-bottom: 10px;
  }
  @media all and (max-width:767px){
    h1{
      font-size: 22px;
      font-weight: bold;
    }
    .btn-send-loan{
      cursor: pointer;
      position: relative;
      padding: 0;
      width: 48px;
      height: 48px;
      min-height: 48px;
      border-radius: 50%;
      span{
        position: absolute;
        text-indent: -99999px;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' stroke='none' fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2143 8.21429H12.1429C11.9456 8.21429 11.7857 8.05437 11.7857 7.85714V1.78571C11.7857 0.799561 10.9862 0 10 0C9.01385 0 8.21429 0.799561 8.21429 1.78571V7.85714C8.21429 8.05437 8.05437 8.21429 7.85714 8.21429H1.78571C0.799561 8.21429 0 9.01385 0 10C0 10.9862 0.799561 11.7857 1.78571 11.7857H7.85714C8.05437 11.7857 8.21429 11.9456 8.21429 12.1429V18.2143C8.21429 19.2004 9.01385 20 10 20C10.9862 20 11.7857 19.2004 11.7857 18.2143V12.1429C11.7857 11.9456 11.9456 11.7857 12.1429 11.7857H18.2143C19.2004 11.7857 20 10.9862 20 10C20 9.01385 19.2004 8.21429 18.2143 8.21429Z' /%3E%3C/svg%3E%0A") 50% 50% no-repeat;
      }
    }
  }
}
.tabs-footer{

  .btn-send-loan{
    display: none;
    @media all and (max-width:767px){
      display: flex;
      cursor: pointer;
      position: fixed;
      bottom:10px;
      left:0;
      right: 0;
      margin: 0 auto;
      padding: 0;
      width: 48px;
      height: 48px;
      min-width: auto;
      min-height: 48px;
      border-radius: 50%;
      box-shadow: 2px 4px 6px 0px #00000040,
      0px -2px 4px 0px #00000040 inset,
      2px 6px 4px 0px #FFFFFF40 inset,
      0px 1px 4px 0px #370FD0 inset,
      -2px 0px 4px 0px #00000040 inset;


      span{
          position: absolute;
          text-indent: -99999px;
          top:0;
          left:0;
          width: 100%;
          height: 100%;
          background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' stroke='none' fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2143 8.21429H12.1429C11.9456 8.21429 11.7857 8.05437 11.7857 7.85714V1.78571C11.7857 0.799561 10.9862 0 10 0C9.01385 0 8.21429 0.799561 8.21429 1.78571V7.85714C8.21429 8.05437 8.05437 8.21429 7.85714 8.21429H1.78571C0.799561 8.21429 0 9.01385 0 10C0 10.9862 0.799561 11.7857 1.78571 11.7857H7.85714C8.05437 11.7857 8.21429 11.9456 8.21429 12.1429V18.2143C8.21429 19.2004 9.01385 20 10 20C10.9862 20 11.7857 19.2004 11.7857 18.2143V12.1429C11.7857 11.9456 11.9456 11.7857 12.1429 11.7857H18.2143C19.2004 11.7857 20 10.9862 20 10C20 9.01385 19.2004 8.21429 18.2143 8.21429Z' /%3E%3C/svg%3E%0A") 50% 50% no-repeat;
      }
    }
  }
  
}
.tabs-main {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  @media all and (max-width:767px){
    padding: 0;
  }
}

.table-number {
  // white-space: nowrap;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
}
@media(min-width: 768px){
  .table-number {
    text-align: center;
  }
  .table-text {
    max-width: 300px;
    // word-wrap: break-word;
    // word-break: break-word;
    white-space: break-spaces;
  }
}

.text-success {
  color: #1BBA85;
}

.text-secondary {
  color: #6c757d;
}

.text-fail {
  color: #FD0018;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  height: calc(100vh - 64px - 65.8px - 54.4px - 3rem);
  overflow: auto;
  // height: 'calc(100vh - 64px - 65.8px - 54.4px - 3rem)', overflow: 'auto'
  @media all and (max-width:767px){
    height: 100%;
    border-radius: 20px;
  }
}

.card-holder {
  width: 100%;
  margin-bottom: .25rem;
}

.card-item-holder {
  margin-bottom: .25rem;
  &__accept {
    background: rgba(2,119,189,.1);
    padding: .5rem;
  }
}

// loan modal

.loan-input-group {
  margin-bottom: 2.5rem;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;

  .loan-none {
    display: none;
  }
}

.loan-comment {
  // style={{ marginBottom: '1rem' }}
  margin-bottom: 1rem;
}
.ant-input-number{
  border-radius: 10px;
  &:hover{
    border-color: #1BBA85;
  }
}
.ant-input-number-input-wrap{
  .ant-input-number-input{
    height: 40px;
    padding: 0 10px;
    border-radius: 10px;
  }
}
.loan-input-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__start {
    justify-content: flex-start;

    &-check {
      margin-right: 1rem;
      .ant-btn-link {
        padding: 0;
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 24px;
  }
  .ant-input-number{
    border-radius: 10px;
    &:hover{
      border-color: #1BBA85;
    }
  }
  .ant-input-number-input-wrap{
    .ant-input-number-input{
      height: 40px;
      padding: 0 10px;
      border-radius: 10px;
    }
  }
  
  .ant-form-item-control-input-content:focus {
    outline: none;
  }

  .ant-input-affix-wrapper, 
  .ant-input-affix-wrapper-focused {
    border: none;
    padding-right: 0;
    border-radius: 10px;
    background-color: #fff;
    // &:focus {
    //   border: none;
    //   box-shadow: none;
    // }
    // &:hover {
    //   border: none;
    //   box-shadow: none;
    // }
  }

  input {
    border: none;
    text-align: end;
    font-weight: 700;
  }

  .ant-checkbox-wrapper {
    display: flex;
  }

  .ant-checkbox {
    padding-top: 8px;
  }

  // .ant-form input[type='checkbox']:focus {
  //   outline: none;
  // }
  .ant-checkbox-checked::after {
    border: none;
  }
}
.ant-form-item-control-input-content {
  .text-editor{
    border-radius: 10px;
    background-color: white;
    .ql-toolbar.ql-snow {
      border: 0;
      border-bottom: 1px solid #f3f3f3;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .ql-container.ql-snow{
      border:0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
.loan-input {
  // style={{ fontWeight: '700', margin: 0 }}
  font-weight: 700;
  margin: 0;
}

.loan-input-group {
  .ant-slider-mark .ant-slider-mark-text:first-child {
    left: 3% !important;
  }
  .ant-slider-mark .ant-slider-mark-text:last-child {
    // left: 95% !important;
    left:auto!important;
    right: 0;
    transform:translateX(0)!important;
  }
}

.loan-input-group-month {
  .ant-slider-mark .ant-slider-mark-text:first-child {
    left: 2% !important;
  }
  .ant-slider-mark .ant-slider-mark-text:last-child {
    left:auto!important;
    right: 0;
    transform:translateX(0)!important;
  }
}

// more info

.more-info-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  background-color: #f0f2f5;
  border: 1px solid rgba(0,0,0,.125);

  font-family: "Nunito", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: left;
  .ant-row{
    align-items: flex-end;
    .ant-divider-horizontal{
      margin: 10px 0;
    }
  }
}

.loan-card-holder {
  margin-top: 1rem;
  transition: all .3s;
  overflow: hidden;
}

.visible {
  max-height: 750px;
}

.invisible {
	max-height: 0;
}

.site-layout-background {
	.ant-col {
		+ .ant-col {
			text-align: right;
		}
	}
}


// card

@media(min-width: 768px){
	.card-row p {
		width: auto;
		text-align: right;
	}
	.site-layout-background {
		.ant-col {
			+ .ant-col {
				text-align: left;
			}
		}
	}
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  width: 100%;

  p {
    margin: 0;
    font-family: "Nunito", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: left;
    // background-color: #ececec;
  }

  .bold {
    font-weight: 700;
  }

  svg {
    width: 1rem !important;
    height: 1rem !important;
    cursor: pointer;
  }
  img {
    width: 1rem;
    cursor: pointer;
  }

  .text-span {
    color: rgba(0,0,0,.5)!important;
  }

  .text-muted {
    color: #6c757d;
    margin-bottom: .25rem;
  }
}


.card-row {
  display: flex;
  // justify-content: space-between;
  justify-content: flex-end;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  .card-title {
    width: 50%;
    font-weight: 700;
  }
  p {
    margin: 0;
		width: 45%;
		white-space: normal;
  }
}

// .card-row {
//   display: flex;
//   justify-content: flex-end;
// }

.card-row-btns {
  display: flex;
  margin-bottom: .5rem;
  width: 100%;
  justify-content: flex-end;
}

.card-row-btn {
  width: 100%;
}

.card-row-btn__link {
  height: auto;
  a {
    white-space: initial;
  }
}

@media(min-width: 962px){
  .card-row-btns {
    max-width: 300px;
  }
}

.default-tabs-holder {
  height: 70vh;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 64px - 65.8px - 54.4px - 1.5rem);
  overflow: auto;
}



// msgs

.msgs-holder {
  border: 1px solid #dee2e6;
  height: 70vh;
  width: 100%;
  padding: 1rem;
  overflow: auto;
  height: calc(100vh - 64px - 65.8px - 54.4px - 1.5rem);
  overflow: auto;
  // overflow-x: hidden;
  // height: 'calc(100vh - 64px - 65.8px - 54.4px - 1.5rem)', overflow: 'auto'
  // style={{ border: '1px solid #dee2e6 !important', height: '50vh', width: '100%', padding: '1rem' }}
}

.msg-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.reply-msg {
  padding: .25rem 1rem;
  margin: .5rem 0;
  border-left : 2px solid @primary-color;
  position: relative;

  p {
    font-weight: 700 !important;
    margin: 0;
  }

  &-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    // style={{ position: 'absolute', right: '20px', cursor: 'pointer' }}
  }
}

@media (min-width: 768px){
  .card {
    min-width: 500px;
    width: 49%;
  }
}

.text-msg {
  margin-bottom: 1rem !important;
}

.links-holder {
  display: flex;
  flex-flow: row wrap;
  margin:0 0 1rem;
  button {
    padding: 0 1rem 0 0;
  }
  p {
    cursor: pointer;
    color: @primary-color; // #1890ff;
    padding-right: 1rem;
    word-break: break-word;
    // display: flex;
    // align-items: center;
  }
  p:hover {
    text-decoration: underline;
  }
}



.align-left {
  display: flex;
  justify-content: flex-start;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

// card btns
.card-btns-holders {
  display: flex;
  // flex-flow: column wrap;
  div {
    width: auto;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }
  .ant-btn{
    width: auto;
    padding:15px;
  }
  .btn-accept {
    border: 1px solid rgba(122, 134, 154, 0.22);
    height: 50px;
    padding: 15px;
    border-radius: 10px;
    font-weight: 700;
    color:#1BBA85;
  }
  @media all and (max-width:480px){
    flex-wrap: wrap;
    div{
      width:100%;
      margin-right: 0;
      .ant-btn{
        width: 100%;
      }
    }
  }
}

.btn-accept {
  // background-color: #ffed4a !important;
  color: #212529;
  border-color: #1BBA85 !important;

  &:hover {
    color: #212529 !important;
  }
}


.no-data {
  text-align: center;
  width: 100%
}


.table-width {
  width: 100%;
}

.tabs-card-btn {
  // style={{ width: '100%', marginTop: '1rem' }}
  width: 100%;
  margin-top: 1rem;
}

.table-number .ant-btn-link {
  padding: 0;
}
@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;