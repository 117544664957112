.main-header {
  background: white !important;
  // display: flex;
  // justify-content: space-between;
  padding: 0 16px !important;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;

  nav {
    img {
      // width: 40px;
      height: 40px;
      margin-right: 15px;
      @media all and (max-width:480px){
        height: 35px;
      }
    }
    a {
      color: rgba(0,0,0,.9);
      display: inline-block;
      // padding-top: .32rem;
      // padding-bottom: .32rem;
      margin-right: 1rem;
      font-size: 1.125rem;
      line-height: inherit;
      white-space: nowrap;
      @media all and (max-width:480px){
        margin-right: 0;
      }
    }
    a:hover {
      color: rgba(0,0,0,.9);
    }
  }
}

.esia-page {
  .ant-form-item-label > label.ant-form-item-required::before {
    content: ''
  }
  .ant-card-head-title {
    white-space: normal;
  }
  .ant-checkbox-wrapper {
    display: flex;
  }

  .ant-checkbox {
    padding-top: 4px;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .esia-block {
    margin: 1rem;
    transition: all .3s;
  }

  .esia-primary {
    background-color: color(~`colorPalette('@{primary-color}', 5) `);
  }

  .ant-list-item {
    justify-content: flex-start;
    .doc-icon {
      padding-right: .5rem;
    }
  }

}

.clickable-item {
  cursor: pointer;
  color: @primary-color !important;
  &:hover {
    color: color(~`colorPalette('@{primary-color}', 5) `);
  }
}

// .ant-list-item {
//   cursor: pointer;
//   color: @primary-color !important;
//   // justify-content: flex-start;
//   &:hover {
//     color: color(~`colorPalette('@{primary-color}', 5) `);
//   }
// }

@media(max-width:768px){
  .esia-block{
    width: 100%;
  }
}

.unactive-block {
  pointer-events: none;
  opacity: .5;
}

.ant-layout-header {
  height: auto !important;
}

.ant-dropdown-link {
  color: rgba(0,0,0,.5);
  white-space: nowrap;
  padding: 0;

  &:hover {
    color: rgba(0,0,0,.7)
  }
}

.cards-margin-bottom {
  margin-bottom: .5rem;
}

.loan-card-holder {
  margin-top: 1rem;
  transition: all .3s;
  overflow: hidden;
}

.visible {
  max-height: 750px;
}

.invisible {
	max-height: 0;
}

@main-logo-color: #000AFF;@second-logo-color: #FD0018;@primary-color: @main-logo-color;@link-color: #000AFF;@layout-sider-background: @primary-color;@menu-dark-item-active-bg: @second-logo-color;@menu-dark-bg: @primary-color;